// Globals
$border-color: #e5e5e5;
$border-color-light: #f1f1f1;
$sidebar-padding: 20px;
$button-padding: 14px;

// Text-editor
$editor-border-color: rgba(130, 130, 130, 0.82);

// Font families
$font-family-primary: "Lora", serif;
$font-family-secondary: "Roboto", sans-serif;

// Colors
$color-blackish: #1f1f1f;
$color-greyish: #3a3a3a;
$color-whiteish: #fbfbfb;
$color-green-lighter: #43a03b;
$color-green: #298c20;
$color-mediumgreen: #1d6817;
$color-lightgray: #f8f8f8;
$color-red: #d60a0a;
$color-blue-lighter: #268bef;
$color-blue: #007af0;
$color-foggyblue: #2b6694;
$color-mediumblue: #0054a4;
$color-darkblue: #003b69;
$color-yellow: #f8cd23;
$color-orange: #e48046;

// Warning level colors
$forbidden: $color-red;
$forbidden-light: #ffe4e4;
$warning: $color-orange;
$warning-light: #fff3c5;
$consider: $color-green;
$consider-light: #dff4dd;

$options-bar-height: 45px;

$sidebar-width: 406px;

$main-container-max-width: 940px;
$main-container-padding-top: 20px;
$main-container-padding-right: 20px;
$main-container-padding-bottom: 0px;
$main-container-padding-left: 80px;

$level-padding-width: 30px;
