.version-selectors {
  margin-bottom: calcRem(22px);
  font-family: $font-family-secondary;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &__comparison {
    display: flex;
    justify-content: space-between;
  }

  &__toggle-versions-button,
  &__translation-button {
    margin-right: 10px;
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(15px);
    color: $color-darkblue;
    padding-left: calcRem(25px);
    padding-top: calcRem(2px);
    font-size: calcRem(15px);
    &:hover {
      text-decoration: underline;
      color: $color-mediumblue;
    }
    &:active {
      border: none;
    }
  }

  &__toggle-versions-button {
    background: url("../../img/icon-history-dark-blue.svg") no-repeat left center;
    &:hover {
      text-decoration: underline;
      color: $color-mediumblue;
      background: url("../../img/icon-history-blue.svg") no-repeat left center;
    }
  }

  &__translation-button {
    margin: 0 0 0 10px;
    min-width: max-content;
    background: url("../../img/icon-translate.svg") no-repeat left center;

    &:hover {
      background: url("../../img/icon-translate-active.svg") no-repeat left center;
    }
  }
}
