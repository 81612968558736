.workflow-display {
  display: flex;
  justify-content: flex-center;

  // text-align: center;
  &__workflow-step-container {
    text-align: start;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
  }
  &__workflow-step-text-container {
    padding: 0 calcRem(20px);
    font-weight: bold;
    font-family: $font-family-secondary;
    white-space: nowrap;
    &--active {
      position: relative;
      height: 120%;
    }
  }

  &__workflow-step {
    font-weight: normal;
    &--completed {
      opacity: 0.3;
    }
    &--active {
      font-weight: bold;
    }
    &--inactive {
      opacity: 0.3;
    }
  }
}
