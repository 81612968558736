.sidebar-navigation {
  background: #eee;
  border-radius: 5px;
  padding: 9px;
  display: flex;
  margin-bottom: calcRem(16px);

  &__item {
    flex: 0 0 50%;
    font-weight: bold;
    font-size: calcRem(14px);
    padding: 7px 0;
    border-radius: 5px;

    &:hover {
      color: $color-mediumblue;

      .sidebar-navigation__item-notification {
        background: $color-mediumblue;
      }
    }

    &--active {
      background: #fff;
      border: 1px solid $border-color;

      &:hover {
        color: $color-blackish;

        .sidebar-navigation__item-notification {
          background: $color-blue;
        }
      }

      .sidebar-navigation__item-notification {
        background: $color-blue;
      }
    }

    &-notification {
      background: $color-foggyblue;
      color: #fff;
      font-family: $font-family-secondary;
      font-size: calcRem(12px);
      font-weight: bold;
      border-radius: 9px;
      padding: 2px 5px;
      margin-left: 2px;
    }
  }
}