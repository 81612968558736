.field-comment-list {
  margin-right: -$sidebar-padding;
  margin-left: -$sidebar-padding;
  &__list-item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
    &:not(:first-child) {
      padding-top: calcRem(25px);
    }
    &:nth-child(2) {
      background: #F5F5F5;
    }

    // &:nth-child(2n) {
    //   background-color: red;
    // }
  }
  &__label {
    font-family: $font-family-secondary;
    font-size: calcRem(16px);
    font-weight: bold;
    color: $color-darkblue;
    text-align: center;
    margin-bottom: calcRem(23px);
  }
}
