.options-bar {
  background-color: white;
  position: fixed;
  display: flex;
  z-index: 2;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: $options-bar-height;
  top: 0;
  &--comparison {
    padding-left: 80px;
    flex-grow: 1;
  }

  &__arrow-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: calcRem(5px);
  }
  &__arrow {
    transition: 0.3s;
    top: 13px;
    left: 5px;
    &--active {
      transform: rotate(180deg);
    }
  }
  &__arrow-left {
    margin-left: 10px;
    transform: rotate(180deg);
  }
  &__versions-list {
    padding-top: 20px;
  }
  &__versions-list-title-span {
    flex-grow: 1;
  }
  &__version-item {
    font-family: $font-family-secondary;
    padding: calcRem(5px) calcRem(10px);
  }
  &__versions-list-title {
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    margin-bottom: calcRem(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }
  &__versions-container {
    width: 270px;
    padding-left: 10px;
    padding-top: 10px;
  }

  &__button-next {
    font-size: 30px;
  }
  &__button-prev {
    font-size: 30px;
  }

  &__spacer {
    height: $options-bar-height;
    width: 100%;
  }

  &__locales-container {
    display: flex;
    flex-direction: row;
    flex: 1 0 940px;
    justify-content: center;
  }

  &--comparable {
    &__locales-container {
      padding-left: $main-container-padding-left;
    }
  }

  &__button-container {
    width: $sidebar-width;
    justify-content: flex-end;
    display: flex;
    padding: 11px 0px
  }
}
