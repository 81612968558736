.login__title {
  margin: 0 auto 1rem;
  font-size: 2rem;
}

.login {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 15rem auto 0;
}

.login__field {
  margin-bottom: 1rem;
}

.login__label {
  margin-bottom: 0.5rem;
}

.login__input-container {
  display: flex;
  flex-direction: column;
}

.login__input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
}

.login__button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: $color-blackish;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
