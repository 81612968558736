.button {
  border-radius: 0.5rem;
  padding: 10px 14px;
  font-family: $font-family-secondary;
  flex-grow: 1;
  font-size: calcRem(14px);
  font-weight: bold;
  transition: background-color 0.2s;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &--primary {
    background: $color-darkblue;
    color: #fff;

    &:hover {
      background: $color-mediumblue;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    }
    &:active {
      background: darken($color-mediumblue, 5%);
      box-shadow: none;
    }
  }
  &--secondary {
    background: $color-whiteish;
    color: $color-darkblue;
    border: 1px solid $color-darkblue;

    &:hover {
      background: #fff;
      border-color: $color-mediumblue;
      color: $color-mediumblue;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    }
  }

  &:disabled {
    opacity: 0.2;
    cursor: initial;
  }
}
