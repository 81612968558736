.options-item {
  &:disabled {
    opacity: 0.3;
    cursor: unset;
  }
  &__icon {
    height: calcRem(20px);
    width: calcRem(20px);
  }
  &__tooltip {
    font-family: $font-family-secondary !important;
    font-weight: bold;
  }
  padding: 0 10px;
  &:not(:first-child){
    border-left: 1px solid $border-color
  }
}
