.message-display {
  position: relative;
  background-color: $forbidden-light;
  border: 2px solid rgba(255, 0, 0, 0.308);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-family: $font-family-secondary;
  margin-bottom: calcRem(10px);
  &__close-icon {
    position: absolute;
    right: calcRem(10px);
    top: calcRem(7px);
  }
}
