.retranslation-editor {
  &__field-container {
    background: transparent;
  }

  &__header {
    margin-bottom: calcRem(10px);
  }
  
  &__label {
    display: inline-block;
  }

  &__button {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(11px);
    text-transform: uppercase;
    position: absolute;
    right: 12px;
    border: 1px solid black;
    border-radius: 4px;
    line-height: 1;
    padding: 7px 16px;
    cursor: pointer;
    background: black;
    color: white;
  }
}