.container__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &--contained {
    padding: 0 20px;
    max-width: 1000px;
    @media only screen and (max-width: 1000px) {
      width: 1000px;
    }

    @media only screen and (min-width: 1000px) {
      width: 100%;
    }
  }

  &--full {
    padding: 0 120px;
    width: 100%;
  }
}
