.preview-modal {
  flex: 1 0 100%;

  &__container {
    position: fixed;
    z-index: 2;
    background: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: $options-bar-height;
    flex-direction: column;
    justify-content: flex-start;

    overflow: auto;
  }

  &__entity-wrapper {
    padding: 25px 0;
    border-bottom: 1px dashed $border-color;
    background-color: white;

    &--comparison {
      padding: 25px 0;
      border-bottom: 1px dashed $border-color;
      background-color: white;
    }
  }

  &__fields-container--comparison {
    display: flex;
    flex-direction: row;
    & > p,
    h1,
    h2,
    h3 {
      flex: 1 0 50%;
      &:first-child {
        padding-right: 40px;
      }
      &:last-child {
        padding-left: 40px;
      }
    }
  }
}
