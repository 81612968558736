.text-area {
  background-color: $color-whiteish;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgb(205,205,205)' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  padding: 11px;
  font-size: calcRem(14px);
  
  &:hover, &:focus {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.07);
  }

  &:focus-within {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgb(0,59,105)' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &__field {
    border: 0;
    outline: none;
    background: none;
    width: 100%;
    line-height: 1.2;
  }

  &__error-message {
    font-family: $font-family-secondary;
    font-size: calcRem(14px);
    background: $forbidden-light;
    border-radius: 5px;
    margin-top: calcRem(10px);
    padding: calcRem(10px) calcRem(14px);
  }
}
