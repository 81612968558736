.new-comment {
  font-family: $font-family-secondary;
  padding-top: calcRem(10px);

  &__title {
    margin-bottom: calcRem(20px);
    text-align: center;
    font-size: calcRem;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__quote {
    font-family: $font-family-primary;
    background: #EBF1F6;
    border: 1px solid #D8ECFE;
    border-radius: 2px;
    font-size: calcRem(16px);
    font-style: italic;
    background: #EBF1F6 url('../../img/icon-quote.svg') no-repeat 10px 10px;
    padding: 10px 10px 10px 32px;
    margin-bottom: 16px;
    flex: 0 0 100%;
  }

  &__label {
    font-size: calcRem(14px);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: calcRem(10px);
  }

  &__topic {
    margin-bottom: calcRem(20px);
  }

  &__text {
    margin-bottom: calcRem(30px);

    .text-area__field {
      resize: none;
    }
  }

  &__character-count{
    text-align:right;
    font-size: calcRem(13px);
    font-weight: bold;
    margin-top:calcRem(4px);
  }
}