.options-bar-locale-selector {
  flex: 0 1 $main-container-max-width;
  padding: 0 $main-container-padding-right 0 $main-container-padding-left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--comparison {
    flex-grow: 1;
    padding: 0;
  }
}
