.main-container {
  position: relative;
  padding: $main-container-padding-top $main-container-padding-right $main-container-padding-bottom
    $main-container-padding-left;
  flex: 0 1 $main-container-max-width;

  &__inactive {
    opacity: 0.25;
  }

  &--comparison {
    flex-grow: 1;

    .comparable {
      display: flex;
    }
  }
}

.loading-icon {
  z-index: 2;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: url("../../img/spinner-puff.svg") no-repeat center center;
}

.loading-translation {
  opacity: 0.3;
}
