.phrase-header {
  background: #fff;
  padding: $sidebar-padding;
  border-left: 1px solid $border-color;

  &__back-btn {
    width: 19px;
    height: 16px;
    background: url("../../img/icon-arrow-left-black.svg") no-repeat top left;
    text-indent: -9999px;
    display: block;
    margin-bottom: calcRem(10px);

    &:hover {
      background-image: url("../../img/icon-arrow-left-mediumblue.svg");
    }
  }

  &__title {
    font-size: calcRem(38px);
    font-weight: 500;
    text-decoration: underline solid 5px;
    line-height: 1.2;
    margin-bottom: calcRem(8px);
    // text-transform: capitalize; //Tentipi did not wish to have letters capitalized
  }

  &__description {
    margin-bottom: calcRem(8px);
  }

  &__warning-level {
    font-family: $font-family-secondary;
    font-size: calcRem(14px);
    font-weight: bold;
    margin-bottom: calcRem(30px);
    line-height: 1;
    padding-top: 2px;
  }

  &__author {
    font-family: $font-family-secondary;
    font-size: calcRem(14px);
    color: #b2b2b2;
  }

  &--forbidden {
    .phrase-header__title {
      text-decoration-color: $forbidden;
    }
    .phrase-header__warning-level {
      color: $forbidden;
      background: url("../../img/icon-alert-forbidden.svg") no-repeat center left;
      padding-left: 22px;
    }
  }

  &--warning {
    .phrase-header__title {
      text-decoration-color: $warning;
    }
    .phrase-header__warning-level {
      color: $warning;
      background: url("../../img/icon-alert-warning.svg") no-repeat center left;
      padding-left: 22px;
    }
  }

  &--established {
    .phrase-header__title {
      text-decoration-color: $consider;
    }
    .phrase-header__warning-level {
      color: $consider;
      background: url("../../img/icon-alert-consider.svg") no-repeat center left;
      padding-left: 22px;
    }
  }
}
