.hits-list {
  &__containe {
    display: flex;
    overflow-y: auto;
  }

  &__list {
    max-width: 100%;
    height: min-content;
  }

  &__pre {
    max-width: 100%;
    overflow-x: hidden;
  }

  &__item {
    background: #fff;
    margin-bottom: calcRem(10px);
    border-radius: 10px;
    border: 1px solid $border-color-light;
    padding-right: 20px;

    &:hover {
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
      border-color: $border-color;

      .hits-list__link {
        color: $color-mediumblue;
        background-image: url("../../img/icon-arrow-right-blue.svg");
      }
    }

    &--forbidden {
      .hits-list__link {
        span {
          color: $forbidden;
        }
        &::before {
          background: $forbidden;
        }
      }
    }

    &--warning {
      .hits-list__link {
        span {
          color: $warning;
        }
        &::before {
          background: #f6c60e;
        }
      }
    }

    &--established {
      .hits-list__link {
        span {
          color: $consider;
        }
        &::before {
          background: $consider;
        }
      }
    }
  }

  &__link {
    width: 100%;
    color: $color-blackish;
    text-decoration: none;
    font-weight: 500;
    padding: 11px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    background: url("../../img/icon-arrow-right.svg") no-repeat right center;

    span {
      margin-left: auto;
      font-size: calcRem(12px);
    }

    &::before {
      content: "";
      width: 7px;
      height: 7px;
      background: $color-blackish;
      display: block;
      border-radius: 3.5px;
      margin-right: 12px;
    }
  }
}
