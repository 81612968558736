.locale-selector {
  display: flex;
  align-items: center;

  &__full {
    border-bottom: 1px solid $color-blackish;
    padding-bottom: calcRem(12px);
    margin-bottom: calcRem(22px);
  }

  &__label {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(15px);
    margin-right: calcRem(10px);
  }

  &__close-btn {
    margin-left: auto;
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(15px);
    color: $color-darkblue;
    background: url("../../img/icon-close-blue.svg") no-repeat left center;
    padding-left: calcRem(15px);

    &:hover {
      text-decoration: underline;
      color: $color-mediumblue;
      background-image: url("../../img/icon-close-mediumblue.svg");
    }
  }

  // Reset default select styling
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0;
    background: none;
    background-image: none;
  }
  // Remove IE arrow
  select::-ms-expand {
    display: none;
  }

  // Apply custom styling to select element
  select {
    font-size: calcRem(16px);
    font-weight: 500;
    cursor: pointer;
    padding-right: calcRem(16px);
    background: url("../../img/icon-expand.svg") no-repeat right 14px center;
    padding: calcRem(6px) calcRem(40px) calcRem(6px) calcRem(14px);
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid $border-color-light;

    &:hover {
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
      border-color: $border-color;
      background-image: url("../../img/icon-expand-mediumblue.svg");
      color: $color-mediumblue;
    }
  }
}
