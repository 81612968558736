.action-bar {
  display: flex;
  width: 100%;
  align-items: center;
  background: $color-lightgray;
  z-index: 1;
  justify-content: center;
  padding-top: calcRem(25px);
  border-top: 1px solid #e9e9e9;

  button {
    flex: 0 1 230px;

    &:not(:last-child) {
      margin-right: calcRem(20px);
    }
  }
}