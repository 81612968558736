.textlink-select {
  font-family: $font-family-secondary;
  font-weight: 500;
  position: sticky;
  top: 8px;
  align-self: flex-start;
  margin-top: calcRem(20px);
  padding: calcRem(6px) calcRem(14px);
  border-radius: 5px;
  border: none !important;
  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.04);
    border-color: $border-color;
  }

  // Reset default select styling
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: none;
    background-image: none;
  }
  // Remove IE arrow
  select::-ms-expand {
    display: none;
  }

  // Apply custom styling to select element
  select {
    color: $color-darkblue;
    cursor: pointer;
    background: url("../../img/icon-expand-blue.svg") no-repeat right center;
    padding-right: calcRem(16px);

    &:hover {
      color: $color-mediumblue;
      background-image: url("../../img/icon-expand-mediumblue.svg");
    }
  }
}
