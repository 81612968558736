.home {
  &__inner-container {
    display: flex;
    position: relative;
  }

  &__main {
    display: flex;
    justify-content: center;
    flex: 1 1 100%;
  }
}
