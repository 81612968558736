.hits-filter {
  display: flex;
  margin-bottom: calcRem(24px);
  justify-content: space-between;

  &__item {
    font-size: calcRem(16px);
    font-weight: bold;
    text-align: center;
    opacity: .6;

    span {
      font-family: $font-family-secondary;
      font-size: calcRem(14px);
      display: block;
      color: $color-blackish;
    }

    &:hover {
      opacity: .8;

      span {
        color: $color-mediumblue;
      }
    }

    &--active {
      opacity: 1;
      
      span {
        text-decoration: underline;
        color: $color-blue;
      }

      &:hover {
        opacity: 1;

        span {
          color: $color-blue;
        }
      }
    }



    &--forbidden {
      color: $forbidden;
    }

    &--warning {
      color: $warning;
    }

    &--established {
      color: $consider;
    }

    &:not(:last-child) { 
      margin-right: 10px;
    }
  }
}