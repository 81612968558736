.reply-form {
  font-family: $font-family-secondary;
  width: 100%;
  margin-top: $sidebar-padding;

  &__comment-field {
    display: flex;

    .text-area__field {
      resize: none;
    }
  }

  &__submit-btn {
    color: #fff;
    font-size: calcRem(14px);
    text-transform: uppercase;
    font-weight: bold;
    background: $color-darkblue;
    padding: 1px 12px;
    border-radius: 4px;
    margin-left: 10px;
    transition: background-color .2s;

    &:hover {
      background: $color-mediumblue;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    }

    &:active {
      background: darken($color-mediumblue, 5%);
      box-shadow: none;
    }
  }
}