.comment-item {
  display: flex;
  flex-wrap: wrap;
  padding-left: $sidebar-padding;
  padding-right: $sidebar-padding;
  padding-bottom: calcRem(20px);

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
    margin-bottom: $sidebar-padding;
  }

  &__quote {
    background: #ebf1f6;
    border: 1px solid #d8ecfe;
    border-radius: 2px;
    font-size: calcRem(14px);
    font-style: italic;
    background: #ebf1f6 url("../../img/icon-quote.svg") no-repeat 14px 6px;
    padding: 2px 10px 2px 40px;
    margin-bottom: 16px;
    flex: 0 0 100%;
    position: relative;

    &--expandable {
      cursor: pointer;

      &:hover {
        background-color: #ddedfb;
        border-color: #b0d6fb;
      }
    }

    &--expanded {

    }
  }

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    background: $color-blue;
    color: #fff;
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(16px);
    line-height: 1;
    flex: 0 0 34px;
    border-radius: 17px;
    margin-right: 8px;
  }

  &__container {
    flex: 1 1 0;
    font-family: $font-family-secondary;
    display: flex;
    flex-wrap: wrap;
  }

  &__meta-container {
    flex: 1 1 0;
  }

  &__author {
    font-weight: bold;
    font-size: calcRem(14px);
    flex: 1 0 100%;
  }

  &__date-time {
    font-size: calcRem(12px);
    color: #7e7e7e;
    margin-bottom: calcRem(5px);
    flex: 1 0 100%;
  }

  &__comment {
    font-size: calcRem(14px);
    margin-bottom: calcRem(5px);
    flex: 1 0 100%;

    &--expandable {
      cursor: pointer;

      &:hover {
        color: #004a84;
      }
    }

    &--expanded {
      
    }
  }

  &__toggle-replies {
    font-size: calcRem(14px);
    font-weight: bold;
    color: $color-darkblue;

    &:hover {
      text-decoration: underline;
      color: $color-mediumblue;
    }
  }

  &__reply {
    font-size: calcRem(14px);
    font-weight: bold;
    color: $color-darkblue;
    margin-left: auto;
  }

  &__actions-container {
    margin-left: auto;
    flex: 0 0 105px;
    text-align: right;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__delete {
    font-size: calcRem(12px);
    font-weight: bold;
    color: $color-darkblue;

    &:hover {
      color: $color-red;
      text-decoration: underline;
    }
  }

  &__toggle-resolve {
    font-size: calcRem(12px);
    font-weight: bold;
    color: $color-green;

    &:hover {
      text-decoration: underline;
    }
  }

  &__subcomments-container {
    margin-top: calcRem(20px);
    border-top: 1px solid $border-color;
    padding-top: calcRem(20px);
    width: 100%;

    .comment-item {
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid $border-color;

      &__comment {
        margin-bottom: 0;
      }
    }
  }
}
