.comments-list {
  &__filter {
    padding-right: $sidebar-padding;
    padding-left: $sidebar-padding;
    margin-bottom: $sidebar-padding;

    &-button {
      font-family: $font-family-secondary;
      font-size: calcRem(14px);
      font-weight: bold;
      color: $color-blackish;
      opacity: .6;

      &:not(:last-child) {
        margin-right: calcRem(20px);
      }

      &:hover {
        color: $color-mediumblue;
        opacity: .8;
      }

      &--active {
        color: $color-blue;
        text-decoration: underline;
        opacity: 1;

        &:hover {
          color: $color-blue;
          opacity: 1;
        }
      }
    }
  }
}
