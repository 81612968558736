.save-button {
  &__button {
    font-family: $font-family-secondary;
    width: calcRem(64px);
    height: calcRem(64px);
    border-radius: 10000px;
    position: fixed;
    bottom: calcRem(23px);
    right: calcRem(23px);
    z-index: 10;
    background-color: $color-green;
    color: white;
    font-weight: bold;
    transition: 0.2s;
    box-shadow: 6px 9px 17px -2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 6px 9px 17px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 9px 17px -2px rgba(0, 0, 0, 0.75);
    &:hover {
      background-color: $color-green-lighter;
      color: white;
    }
    &:active {
      background-color: $color-mediumgreen;
    }
  }
}
