// If comparison mode is active, add padding to text editor containers to allow some space for the "Read-only" label,
// and let field containers fill the full vertical space
.main-container--comparison {
}
// TextEditor main style:
.text-editor {
  margin-bottom: calcRem(15px);

  &__field-description,
  &__field-error {
    background: #2b6694;
    // border: 2px solid black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 10000px;
    flex: 0 0 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
  }

  &__field-error {
    background: rgb(210, 5, 5);
  }

  &__label-container {
    display: flex;
    margin-bottom: calcRem(10px);
    text-align: center;
    align-items: center;

    .text-editor__button-container {
      display: flex;
      margin-left: auto;
      margin-right: 12px;
    }
  }

  &--inactive {
    opacity: 0.55;
  }

  &--active {
    .text-editor__field-container {
      border: 1px solid $border-color;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      &:hover,
      &:focus {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      }
    }

    .text-editor__field-title--active {
      color: $color-blue;
    }

    // If element is a comparison language element, override styling as follows:
    .comparable__item--comparison {
      .text-editor__field-container {
        border: 1px solid $border-color;
        box-shadow: unset;
      }

      .text-editor__field-title--active {
        color: $color-blue;
      }
    }
  }

  .comparable__item {
    position: relative;

    .text-editor {
      &__field-container {
        // padding-top: 43px;
        flex: 1 1 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .comparable__item--comparison {
    .text-editor__field-container {
      background-color: unset;
      &:hover,
      &:focus {
        box-shadow: unset;
      }
    }
  }

  &__field-container {
    background-color: $color-whiteish;
    padding: 20px;
    font-size: calcRem(18px);
    line-height: 1.67;
    border: 1px solid $border-color-light;
    border-radius: 10px;
    display: flex;
    position: relative;

    &:hover,
    &:focus {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
    }

    .DraftEditor-root {
      width: 100%;
    }
  }

  &__readonly-label {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(11px);
    text-transform: uppercase;
    background: url("../../img/icon-readonly.svg") #f3f3f3 no-repeat 12px center;
    background-size: 13px;
    padding: 7px 16px 7px 30px;
    display: block;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    line-height: 1;
  }

  &__re-translation-label {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(11px);
    text-transform: uppercase;
    position: absolute;
    top: 0px;
    right: 12px;
    border: 1px solid black;
    border-radius: 4px;
    line-height: 1;
    padding: 7px 16px;
    cursor: pointer;
  }

  &__translation-label {
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: calcRem(11px);
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 4px;
    line-height: 1;
    padding: 7px 16px;
    margin-left: 12px;
    cursor: pointer;
  }

  &__field-title {
    font-family: $font-family-secondary;
    display: inline-block;
    font-size: calcRem(16px);
    text-transform: uppercase;
  }

  &__hit {
    padding: 2px 0 4px 0;

    &--forbidden {
      background: $forbidden-light;
    }
    &--warning {
      background: $warning-light;
    }
    &--consider {
      background: $consider-light;
    }
  }

  &__add-comment-btn {
    flex: 0 0 46px;
    width: 46px;
    height: 46px;
    border-radius: 23px;
    border: 1px solid #003b69;
    background: url("../../img/icon-comment-bubble.svg") $color-whiteish no-repeat center;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: -23px;
    transition: all linear 0.12s;
    z-index: 1;
    animation: expand 0.2s ease-in-out;

    &:hover {
      background: url("../../img/icon-comment-bubble-white.svg") $color-darkblue no-repeat center;
    }
  }
}
@keyframes expand {
  from {
    transform: scale(0);
  }
}

.loading-translation {
  opacity: 0.3;
}

.loading-icon {
  z-index: 2;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: url("../../img/spinner-puff.svg") no-repeat center center;
}
