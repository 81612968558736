.select-field {
  font-family: $font-family-secondary;
  font-size: calcRem(14px);
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgb(205,205,205)' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
  background-color: $color-whiteish;
  padding: 15px;
  margin-bottom: 0px;

  &:hover, &:focus {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.07);
  }

  // Reset default select styling
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: none;
    background-image: none;
  }
  // Remove IE arrow
  select::-ms-expand {
    display: none;
  }

  // Apply custom styling to select element
  select {
    cursor: pointer;
    width: 100%;
    background: url("../../img/icon-expand.svg") no-repeat right center;
  }

  &__error-message {
    font-family: $font-family-secondary;
    font-size: calcRem(14px);
    background: $forbidden-light;
    border-radius: 5px;
    margin-top: calcRem(10px);
    padding: calcRem(10px) calcRem(14px);
  }

  &__margin-spacer {
    margin-bottom: 20px;
  }
}
