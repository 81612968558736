.document-title {
  &__container {
    display: flex;
    align-items: flex-end;
    margin-bottom: calcRem(40px);
    justify-content: space-between;
  
    &--editing {
      margin-bottom: 0;
      opacity: .35;
      filter: blur(1.3px);
    }
  }

  &__heading {
    font-size: calcRem(36px);
    line-height: 1.28;
    font-weight: 500;
  }

  &__save-btn {
    font-family: $font-family-secondary;
    color: #fff;
    font-size: calcRem(14px);
    text-transform: uppercase;
    font-weight: bold;
    background: $color-darkblue;
    padding: 1px 12px;
    border-radius: 4px;
    margin-left: 10px;
    height: calcRem(28px);
    transition: margin 200ms;
  }

  &__edit-btn {
    font-size: calcRem(14px);
    font-family: $font-family-secondary;
    font-weight: bold;
    color: $color-darkblue;
    margin-left: calcRem(20px);
    margin-bottom: calcRem(4px);
    flex: 0 0 auto;

    &:hover {
      text-decoration: underline;
      color: $color-mediumblue;
    }
  }
}
.text-editor {
  &--selected-content {
    .document-title {
      &__save-btn {
        margin-right: 14px;
      }
    }
  }
}