.phrase-suggestions {
  &__list {
    margin-right: -$sidebar-padding;
    margin-left: -$sidebar-padding;
  }

  &__action-bar {
    display: flex;
    flex-direction: row;
  }

  &__btn {
    font-family: $font-family-secondary;
    font-size: calcRem(14px);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: calcRem(14px);
    &:not(:last-child) {
      margin-right: calcRem(10px);
    }
    &:hover {
      color: $color-mediumblue;
      opacity: 0.8;
    }
    &--active {
      margin-bottom: calcRem(14px);
      &:not(:last-child) {
        margin-right: calcRem(10px);
      }
      font-family: $font-family-secondary;
      text-transform: uppercase;
      font-weight: bold;
      font-size: calcRem(14px);
      color: $color-blue;
      text-decoration: underline;
      margin-bottom: calcRem(14px);

      &:not(:last-child) {
        margin-right: calcRem(10px);
      }
    }
  }
  &__main-item {
    padding-left: $sidebar-padding;
    padding-right: $sidebar-padding;
    padding-bottom: $sidebar-padding + 2;
    .phrase-suggestions__term {
      text-decoration: underline;
      // text-transform: capitalize; //Tentipi did not wish to have letters capitalized
    }
    &:not(:last-child) {
      margin-bottom: $sidebar-padding;
      border-bottom: 1px solid $border-color;
    }
  }
  &__item {
    padding-left: $sidebar-padding;
    padding-right: $sidebar-padding;
    padding-bottom: $sidebar-padding + 2;

    &:not(:last-child) {
      margin-bottom: $sidebar-padding;
      border-bottom: 1px solid $border-color;
    }
  }

  &__term {
    font-weight: 500; // text-transform: capitalize; //Tentipi did not wish to have letters capitalized

    font-size: calcRem(24px);
    color: $color-green;
    line-height: 1.2;
    margin-bottom: calcRem(8px);
  }

  &__definition {
    font-size: calcRem(16px);
  }
}
