$container-spacing: 10px;

.app-navbar {
  font-family: $font-family-secondary;
  width: 100%;
  height: 36px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__workflow-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    button {
      margin: 0 $container-spacing;
    }
  }
  &__heading {
    margin: 0 $container-spacing;
    font-weight: bold;
    display: flex;
    flex: 0 1 10%;
  }

  &__button {
    font-weight: bold;
    padding: 0 17px;
    border-radius: 5px;
    color: white;
    &__blue {
      background-color: $color-blue;
      &:active {
        background-color: $color-mediumblue;
      }
      &:hover {
        background-color: $color-blue-lighter;
      }
    }
    &__green {
      background-color: $color-green;
      &:active {
        background-color: $color-mediumgreen;
      }
      &:hover {
        background-color: $color-green-lighter;
      }
    }

    &__arrow-right {
      background-image: url("../../../img/icon-forward.svg");
      padding-right: 30px;
      background-repeat: no-repeat;
      background-position: top 52% right 10px;
    }
    &__arrow-left {
      background-image: url("../../../img/icon-back.svg");
      background-repeat: no-repeat;
      background-position: top 52% left 10px;
      padding-left: 30px;
    }
  }

  &__options {
    &__container {
      position: relative;
      flex: 0 0 18px;
      margin-right: 6px;
    }

    &__holder {
      position: absolute;
      border-radius: 5px;
      padding: 8px;
      top: 100%;
      z-index: 2;
      background-color: white;
      font-family: $font-family-secondary;

      &-item {
        color: black;
        font-weight: normal;
        width: 200px;
      }
    }

    &__button {
      background-color: $color-greyish;
      width: 100%;
      height: 100%;
      background-image: url("../../../img/icon-more.svg");
      background-position: center center;
      background-size: 20px;
    }
  }
}
