.context-header {
  background: $color-blue;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  position: relative;

  &__title {
    font-family: $font-family-secondary;
    font-size: calcRem(14px);
    text-transform: uppercase;
  }

  &__back-btn {
    width: 19px;
    height: 16px;
    background: url('../../img/icon-arrow-left.svg') no-repeat top left;
    text-indent: -9999px;
    position: absolute;
    left: $sidebar-padding;

    &:hover {
      background-image: url('../../img/icon-arrow-left-black.svg');
    }
  }
} 