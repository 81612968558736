.version-selector {
  width: 100%;
  position: relative;
  border: 1px solid $border-color;
  background-color: white;
  border-radius: calcRem(5px);

  &__versions-list {
    padding-top: 20px;
  }

  &__versions-list-title-span {
    flex-grow: 1;
  }

  &__version-item {
    font-family: $font-family-secondary;
    padding: calcRem(5px) calcRem(10px);
    cursor: pointer;
  }

  &__versions-list-title {
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    margin-bottom: calcRem(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
  }

  &__versions-container {
    z-index: 3;
    top: 90%;
    background-color: white;
    padding-top: calcRem(5px);
    border: 1px solid $border-color;
    border-top: none;
    position: absolute;
    width: 100%;
  }

  &__button-next {
    font-size: 30px;
  }

  &__button-prev {
    font-size: 30px;
  }

  &__active-version-button {
    padding: 4px 10px;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    text-align: start;
  }

  &__button-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
  }
  &__button-close {
    margin-right: calcRem(10px);
  }
  &__button {
    margin: calcRem(10px) calcRem(15px);
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: $border-color;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
  }
}
