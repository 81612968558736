// Google fonts

/* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/roboto-v27-latin/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../../fonts/roboto-v27-latin/roboto-v27-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/roboto-v27-latin/roboto-v27-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../../fonts/roboto-v27-latin/roboto-v27-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/roboto-v27-latin/roboto-v27-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('../../fonts/roboto-v27-latin/roboto-v27-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/roboto-v27-latin/roboto-v27-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* lora-regular - latin */
  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/lora-v17-latin/lora-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/lora-v17-latin/lora-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/lora-v17-latin/lora-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/lora-v17-latin/lora-v17-latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
  }
  /* lora-500 - latin */
  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/lora-v17-latin/lora-v17-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/lora-v17-latin/lora-v17-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500.svg#Lora') format('svg'); /* Legacy iOS */
  }
  /* lora-700 - latin */
  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/lora-v17-latin/lora-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/lora-v17-latin/lora-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700.svg#Lora') format('svg'); /* Legacy iOS */
  }
  /* lora-italic - latin */
  @font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 400;
    src: url('../../fonts/lora-v17-latin/lora-v17-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/lora-v17-latin/lora-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/lora-v17-latin/lora-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/lora-v17-latin/lora-v17-latin-italic.svg#Lora') format('svg'); /* Legacy iOS */
  }
  /* lora-500italic - latin */
  @font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 500;
    src: url('../../fonts/lora-v17-latin/lora-v17-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/lora-v17-latin/lora-v17-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/lora-v17-latin/lora-v17-latin-500italic.svg#Lora') format('svg'); /* Legacy iOS */
  }
  /* lora-700italic - latin */
  @font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 700;
    src: url('../../fonts/lora-v17-latin/lora-v17-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/lora-v17-latin/lora-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/lora-v17-latin/lora-v17-latin-700italic.svg#Lora') format('svg'); /* Legacy iOS */
  }